<template id="optasetup">
  <b-container fluid>
    <h3>Correlation Weight Setup</h3>
    <b-row align-v="end" class="mb-2">
      <b-col cols="4">
        <b-form-group label="Correlation Weight Config" class="mb-0">
          <b-form-select
            v-model="config_id"
            :options="config_list"
            value-field="id"
            text-field="name"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-btn
          class="mr-2"
          variant="primary"
          title="Load config"
          @click="loadConfig"
          v-if="config_id"
          ><b-icon icon="folder"></b-icon
        ></b-btn>
        <b-btn
          class="mr-2"
          variant="info"
          title="Update config"
          @click="updateConfig"
          v-if="config_id && items.length > 0 && isMyConfig(config_id)"
          ><b-icon icon="pencil"></b-icon
        ></b-btn>
        <b-btn
          class="mr-2"
          variant="danger"
          title="Delete config"
          @click="eliminaConfig"
          v-if="config_id && isMyConfig(config_id)"
          ><b-icon icon="trash"></b-icon
        ></b-btn>
        <b-btn
          class="mr-2"
          variant="secondary"
          title="Add config"
          aria-controls="collapse"
          @click="newConfig"
          v-if="items.length > 0"
          ><b-icon icon="plus-circle"></b-icon
        ></b-btn>
        <b-btn
          class="mr-2"
          variant="warning"
          title="Load correlations"
          @click="loadCorrelations"
          v-if="config_id && items.length > 0"
          ><b-icon icon="bar-chart-fill"></b-icon
        ></b-btn>
        <b-btn
          class="mr-2"
          variant="primary"
          title="Set correlations to 1"
          @click="setCorrelationsToOne"
          v-if="items.length > 0"
          >1<b-icon icon="arrow-up"></b-icon
        ></b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-collapse id="collapse" v-model="new_config" class="mb-1">
          <b-row align-v="center" class="mt-2 mb-4">
            <b-col cols="4">
              <b-form-input
                v-model="config_name"
                type="text"
                placeholder="Insert new config name"
              ></b-form-input>
            </b-col>
            <b-col cols="1">
              <b-button
                :variant="waiting_config ? 'warning' : 'primary'"
                @click="aggiungiConfig"
                :disabled="waiting_config"
                >Save
                <b-icon
                  icon="arrow-repeat"
                  :animation="waiting_config ? 'spin' : ''"
                ></b-icon
              ></b-button>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
    </b-row>
    <b-row align-v="start" class="mb-1">
      <b-col>
        <b-form-checkbox
          v-model="allSelectedCluster"
          :indeterminate="indeterminate"
          @change="toggleAllCluster"
        >
          {{
            allSelectedCluster ? "Unselect all clusters" : "Select all clusters"
          }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row align-v="start" class="mb-2">
      <b-col v-for="cluster in clusters" :key="cluster.id">
        <b-form-checkbox
          v-model="form.cluster_ids"
          name="cluster_chk"
          :value="cluster.id"
        >
          {{ cluster.name }}<br />
          <div v-for="league in cluster.leagues" :key="league.id">
            <small>{{ league.name }}</small>
          </div>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row align-v="end" class="mb-2">
      <b-col cols="2">
        <b-form-group label="Role group" class="mb-0">
          <b-form-select
            v-model="form.group"
            :options="groups"
            value-field="id"
            text-field="name"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="From" class="mb-0">
          <b-form-datepicker
            v-model="form.from"
            :locale="ita ? 'it' : 'en-US'"
            :dark="true"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="To" class="mb-0">
          <b-form-datepicker
            v-model="form.to"
            :locale="ita ? 'it' : 'en-US'"
            :dark="true"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Minutes" class="mb-0">
          <b-form-select v-model="form.minutes" :options="minutes" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Age from" class="mb-0">
          <b-form-input type="number" v-model="form.age_from"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Age to" class="mb-0">
          <b-form-input type="number" v-model="form.age_to"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-button
          :variant="waiting_cluster ? 'warning' : 'primary'"
          @click="getStatsCluster"
          :disabled="waiting_cluster"
          >Get stats
          <b-icon
            icon="arrow-repeat"
            :animation="waiting_cluster ? 'spin' : ''"
          ></b-icon
        ></b-button>
      </b-col>
    </b-row>
    <b-form-checkbox v-model="norm_view">
      Displays skills normalized between 0 and 1
    </b-form-checkbox>
    <b-table
      id="my-table"
      ref="table"
      sticky-header="600px"
      small
      striped
      dark
      responsive
      hover
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      @filtered="onFiltered"
      :filter-included-fields="['player']"
    >
      <template #head(player)>
        <span>Players ({{ items.length }})</span>
      </template>
      <template #cell(n)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(player)="data">
        <b-row>
          <b-col cols="2">
            <b-form-checkbox
              name="players_sel"
              v-model="players_ids"
              :value="data.value.id"
            >
            </b-form-checkbox>
          </b-col>
          <b-col cols="7">
            <router-link
              :to="{
                name: 'ScoutingViewPlayer',
                params: { id: data.value.id },
              }"
              target="_blank"
              style="cursor: pointer"
            >
              <span
                style="width: 200px; white-space: nowrap; overflow: hidden"
                :title="data.value.name"
                >{{ data.value.name }}</span
              >
            </router-link>
          </b-col>
          <b-col cols="2">
            {{ data.value.role }}
          </b-col>
        </b-row>
      </template>
      <template #cell(index)="data">
        <div :style="'color:#000000;background:' + data.item['index_bg']">
          <strong>{{ data.value }}</strong>
        </div>
      </template>
      <template #cell(age)="data">
        <div :style="'color:#000000;background:' + data.item['age_bg']">
          <strong>{{ data.value }}</strong>
        </div>
      </template>
      <template #cell()="data">
        <div
          :style="
            'color:#000000;background:' + data.item['bg_' + data.field.key]
          "
        >
          <strong>{{
            norm_view ? data.item["norm_" + data.field.key] : data.value
          }}</strong>
        </div>
      </template>
      <template #thead-top>
        <b-tr v-if="items.length > 0">
          <b-th colspan="4">Correlations</b-th>
          <b-th
            v-for="skill_id in skill_princ_fields"
            :key="'sk_' + skill_id"
            class="text-center"
          >
            <span :title="getSkillById(skill_id).label">{{
              correlations[skill_id]
            }}</span>
          </b-th>
          <b-th
            v-for="skill_id in skill_other_fields"
            :key="'sk_' + skill_id"
            class="text-center"
          >
            <span :title="getSkillById(skill_id).label">{{
              correlations[skill_id]
            }}</span>
          </b-th>
        </b-tr>
        <b-tr v-if="items.length > 0">
          <b-th
            colspan="2"
            style="cursor: pointer"
            @click="selectAllSkills"
            :title="
              select_all_skills ? 'Unselect all skills' : 'Select all skills'
            "
            >Skills</b-th
          >
          <b-th
            colspan="2"
            style="cursor: pointer"
            @click="selectMicro"
            :title="select_micro ? 'Select macro' : 'Select micro'"
            >{{ select_micro ? "Macro" : "Micro" }}</b-th
          >
          <b-th
            v-for="skill_id in skill_princ_fields"
            :key="'ch_' + skill_id"
            class="text-center"
            :title="getSkillById(skill_id).label"
          >
            <b-form-checkbox
              name="skills_sel"
              v-model="skills_ids"
              :value="skill_id"
            ></b-form-checkbox>
          </b-th>
          <b-th
            v-for="skill_id in skill_other_fields"
            :key="'ch_' + skill_id"
            class="text-center"
            :title="getSkillById(skill_id).label"
          >
            <b-form-checkbox
              name="skills_sel"
              v-model="skills_ids"
              :value="skill_id"
            ></b-form-checkbox>
          </b-th>
        </b-tr>
        <b-tr v-if="items.length > 0">
          <b-th colspan="4">Principles</b-th>
          <b-th
            class="text-center border-left border-right"
            :style="'cursor:pointer;color:#000000;background:' + bg_opta[index]"
            :colspan="principles_skills[index].length"
            v-for="(item, index) in principles_title"
            :key="'princ_' + index"
            @click="selectPrinciples(index)"
            >{{ item }}</b-th
          >
          <b-th
            class="text-center border-left border-right"
            :colspan="skill_other_fields.length"
            style="cursor: pointer; color: #000000; background: #ffffff"
            @click="selectOthers(11)"
            >OTHERS</b-th
          >
        </b-tr>
      </template>
      <template #custom-foot>
        <b-tr v-if="items.length > 0">
          <b-th colspan="4">Correlations</b-th>
          <b-th
            v-for="skill_id in skill_princ_fields"
            :key="'corr_' + skill_id"
            class="text-center"
          >
            <span :title="getSkillById(skill_id).label">{{
              correlations[skill_id]
            }}</span>
          </b-th>
          <b-th
            v-for="skill_id in skill_other_fields"
            :key="'corr_' + skill_id"
            class="text-center"
          >
            <span :title="getSkillById(skill_id).label">{{
              correlations[skill_id]
            }}</span>
          </b-th>
        </b-tr>
      </template>
    </b-table>
    <b-row align-v="center" v-if="items.length > 0" class="mb-3">
      <b-col cols="2"> Players selected: {{ players_ids.length }} </b-col>
      <b-col cols="2">
        <b-input-group>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Search player"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="1" class="text-right"> Per page </b-col>
      <b-col cols="1">
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
        ></b-form-select>
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
          class="mb-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mb-3">
      <b-col cols="2">
        <b-button
          :variant="waiting_index ? 'warning' : 'primary'"
          :disabled="waiting_index"
          @click="calculateCorrelationAndIndex"
          >Get correlations
          <b-icon
            icon="arrow-repeat"
            :animation="waiting_index ? 'spin' : ''"
          ></b-icon>
        </b-button>
      </b-col>
      <b-col cols="2">
        <b-button
          :variant="waiting_index ? 'warning' : 'info'"
          :disabled="waiting_index"
          @click="calculateIndex"
          >Refresh index
          <b-icon
            icon="arrow-repeat"
            :animation="waiting_index ? 'spin' : ''"
          ></b-icon>
        </b-button>
      </b-col>
      <b-col cols="2" v-if="auth.user.id == 1 || auth.user.id == 2">
        <json-excel :data="excel_data" type="csv">
          <b-button
            >Download Excel
            <b-icon icon="download"></b-icon>
          </b-button>
        </json-excel>
      </b-col>
      <b-col cols="2">
        <b-button variant="danger" @click="resetPlayers"
          >Reset players
          <b-icon icon="list-check"></b-icon>
        </b-button>
      </b-col>
      <b-col cols="2" v-if="auth.user.id == 1 || auth.user.id == 2">
        <b-button variant="warning" @click="applyCorrelationWeights"
          >Apply weights
          <b-icon icon="bar-chart-fill"></b-icon>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-table
          v-if="correlations[1]"
          ref="table_weights"
          sticky-header="600px"
          small
          striped
          dark
          responsive
          hover
          :items="items_weight"
          :fields="fields_weight"
          sort-by="weight"
          :sort-desc="true"
          show-empty
        >
          <template #cell(skill)="data">
            <span :title="data.value.label">
              {{ data.value.name }}
            </span>
          </template>
          <template #cell(weight)="data">
            <strong>{{ data.value }}</strong>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import JsonExcel from "vue-json-excel";
import loading from "@/assets/images/loading.gif";
import Auth from "@/lib/auth.js";
export default {
  data: function () {
    return {
      ita: true,
      form: {
        from: null,
        to: null,
        cluster_ids: [],
        group: null,
        minutes: 300,
        age_from: 0,
        age_to: 0,
      },
      skills: [],
      groups: [
        { id: null, name: "-- Select --" },
        { id: 0, name: "PORTIERI" },
        { id: 1, name: "DIFENSORI CENTRALI" },
        { id: 2, name: "ESTERNI" },
        { id: 3, name: "CENTROCAMPISTI CENTRALI" },
        { id: 4, name: "INTERNI DI CENTROCAMPO" },
        { id: 5, name: "ATTACCANTI CENTRALI" },
        { id: 6, name: "ATTACCANTI ESTERNI" },
      ],
      minutes: [300, 600, 900],
      loading,
      waiting: false,
      no_weight_skills_id: [1, 2, 3, 110, 111, 112, 113, 114, 115],
      clusters: [],
      waiting_cluster: false,
      players: [],
      stats_cluster: [],
      fields: [],
      items: [],
      players_ids: [],
      correlations: {},
      skills_ids: [],
      skill_max: {},
      skill_min: {},
      norm_view: false,
      waiting_index: false,
      skill_range: {},
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
        "#FF9999",
        "#CCCCCC",
        "#6A5ACD",
      ],
      principles_title: [
        "PRESSING",
        "RECOVERY",
        "MARKING",
        "BUILD UP",
        "POSSESSION",
        "ENGAGEMENT",
        "UNMARKING",
        "DRIBBLING",
        "WIDE PLAY",
        "FINISHING",
        "CREATIVITY",
        "GOALKEEPER",
        "SET PLAY",
      ],
      principles_skills: [[], [], [], [], [], [], [], [], [], [], [], [], []],
      skill_princ_fields: [],
      skill_other_fields: [],
      select_skills: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      select_all_skills: true,
      config_list: [],
      config_id: null,
      new_config: false,
      waiting_config: false,
      config_name: null,
      load_config: false,
      select_micro: false,
      fields_weight: [
        { key: "skill", label: "Skill", sortable: true },
        {
          key: "weight",
          label: "Weight",
          sortable: true,
          class: "text-center",
        },
      ],
      totalRows: 1,
      perPage: 30,
      currentPage: 1,
      filter: null,
      allSelectedCluster: false,
      indeterminate: false,
    };
  },

  components: {
    JsonExcel,
  },

  created: function () {
    this.getSkills();
    this.getSkillsPrinciples();
    this.getClusters();
    this.getCorrelationWeightConfig();
  },

  watch: {
    "form.cluster_ids"(newValue, oldValue) {
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelectedCluster = false;
      } else if (newValue.length === this.clusters.length) {
        this.indeterminate = false;
        this.allSelectedCluster = true;
      } else {
        this.indeterminate = true;
        this.allSelectedCluster = false;
      }
    },
  },

  methods: {
    toggleAllCluster(checked) {
      if (checked) {
        for (var i in this.clusters) {
          if (!this.form.cluster_ids.includes(this.clusters[i].id)) {
            this.form.cluster_ids.push(this.clusters[i].id);
          }
        }
      } else {
        this.form.cluster_ids = [];
      }
    },

    getSkills() {
      this.$http.get("/opta/skills/micro").then((response) => {
        this.skills = response.data;
      });
    },

    getSkillsPrinciples() {
      this.principles_skills = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ];
      this.$http.get("/opta/skills/principles").then((response) => {
        var principles = response.data;
        for (var i in principles) {
          var item = principles[i];
          this.principles_skills[item.principio].push(item.skill_id);
        }
      });
    },

    getClusters() {
      this.$http.get("/opta/clusters").then((response) => {
        this.clusters = response.data;
        //  this.clusters.unshift({ id: null, name: "-- Select --" });
      });
    },

    getCorrelationWeightConfig() {
      this.$http.get("/opta/correlation/weight/config").then((response) => {
        this.config_list = response.data;
        this.config_list.unshift({ id: null, name: "-- Select --" });
      });
    },

    newConfig() {
      if (!this.new_config) {
        this.config_name = null;
      }
      this.new_config = !this.new_config;
    },

    aggiungiConfig() {
      if (!this.config_name || this.config_name === "") {
        alert("Insert config name!");
        return;
      }
      this.waiting_config = true;
      var formData = new FormData();
      formData.append("name", this.config_name);
      formData.append("filters", JSON.stringify(this.form));
      formData.append("players_ids", this.players_ids);
      formData.append("skills_ids", this.skills_ids);
      formData.append("correlations", JSON.stringify(this.correlations));
      this.$http
        .post("/opta/add/correlation/weight/config", formData)
        .then((response) => {
          this.new_config = false;
          this.$store.commit("msgPush", {
            msg: "Config added succesfully!",
            status: 1,
          });
          this.config_list = response.data;
          this.config_list.unshift({ id: null, name: "-- Select --" });
          this.waiting_config = false;
        });
    },

    updateConfig() {
      this.waiting_config = true;
      var formData = new FormData();
      formData.append("filters", JSON.stringify(this.form));
      formData.append("players_ids", this.players_ids);
      formData.append("skills_ids", this.skills_ids);
      formData.append("correlations", JSON.stringify(this.correlations));
      this.$http
        .post(
          "/opta/update/correlation/weight/config/" + this.config_id,
          formData
        )
        .then((response) => {
          this.new_config = false;
          this.$store.commit("msgPush", {
            msg: "Config updated succesfully!",
            status: 1,
          });
          this.config_list = response.data;
          this.config_list.unshift({ id: null, name: "-- Select --" });
          this.waiting_config = false;
        });
    },

    eliminaConfig() {
      if (
        confirm("Are you sure you want to delete the selected configuration?")
      ) {
        this.$http
          .get("/opta/delete/correlation/weight/config/" + this.config_id)
          .then((response) => {
            this.$store.commit("msgPush", {
              msg: "Config deleted succesfully!",
              status: 1,
            });
            this.config_id = null;
            this.config_list = response.data;
            this.config_list.unshift({ id: null, name: "-- Select --" });
          });
      }
    },

    loadConfig() {
      if (confirm("Are you sure you want to load this configuration?")) {
        this.load_config = true;
        var config = this.config_list.find((item) => item.id == this.config_id);
        var filters = JSON.parse(config.filters);
        if (filters.cluster_id) {
          filters.cluster_ids = [];
          filters.cluster_ids.push(filters.cluster_id);
          delete filters.cluster_id;
        }
        this.form = filters;
        this.getStatsCluster();
      }
    },

    loadCorrelations() {
      if (confirm("Are you sure you want to load this correlations?")) {
        this.load_config = true;
        var config = this.config_list.find((item) => item.id == this.config_id);
        this.skills_ids = JSON.parse("[" + config.skills_ids + "]");
        this.correlations = JSON.parse(config.correlations);
        this.calculateCorrelationAndIndex();
        this.load_config = false;
      }
    },

    setCorrelationsToOne() {
      if (confirm("Are you sure you want to set all correlations to 1 ?")) {
        this.load_config = true;
        this.correlations = {};
        for (var i in this.skills) {
          this.correlations[this.skills[i].id] = 1;
        }
        this.calculateCorrelationAndIndex();
        this.load_config = false;
      }
    },

    getStatsCluster() {
      if (this.form.cluster_ids.length == 0) {
        alert("Select a cluster!");
        return;
      }
      if (this.form.group == null) {
        alert("Select a role group!");
        return;
      }
      if (!this.form.from || this.form.from === "") {
        alert("Insert from date!");
        return;
      }
      if (!this.form.to || this.form.to === "") {
        alert("Insert to date!");
        return;
      }

      this.waiting_cluster = true;
      this.players_ids = [];
      this.skills_ids = [];
      for (var i in this.skills) {
        if (!this.no_weight_skills_id.includes(this.skills[i].id)) {
          this.skills_ids.push(this.skills[i].id);
        }
      }
      this.correlations = {};
      this.skill_max = {};
      this.skill_min = {};
      this.fields = [];
      this.items = [];
      this.skill_princ_fields = [];
      this.skill_other_fields = [];
      this.stats_cluster = [];
      this.players = [];

      this.$http.post("/opta/cluster/stats", this.form).then((response) => {
        this.stats_cluster = response.data.stats_cluster;
        this.players = response.data.players;

        this.fields.push("n");

        this.fields.push({
          key: "player",
          sortable: true,
          label: "Players",
          stickyColumn: true,
          thStyle: { "min-width": "200px" },
        });

        this.fields.push({
          key: "index",
          sortable: true,
          label: "Index",
          class: "text-center",
          thStyle: { "min-width": "100px" },
        });

        this.fields.push({
          key: "age",
          sortable: true,
          label: "Age",
          class: "text-center",
          thStyle: { "min-width": "100px" },
        });

        for (var i in this.principles_skills) {
          for (var j in this.principles_skills[i]) {
            var skill_id = this.principles_skills[i][j];
            var skill = this.getSkillById(skill_id);

            this.fields.push({
              key: "" + skill.id + "",
              sortable: true,
              label: skill.name.replaceAll(" ", ""),
              headerTitle: skill.label,
              class: "text-center",
              thStyle: { "min-width": "100px", "max-width": "100px" },
            });

            this.skill_princ_fields.push(skill.id);
          }
        }

        for (var i in this.skills) {
          var skill = this.skills[i];
          if (!this.skill_princ_fields.includes(skill.id)) {
            this.fields.push({
              key: "" + skill.id + "",
              sortable: true,
              label: skill.name.replaceAll(" ", ""),
              headerTitle: skill.label,
              class: "text-center",
              thStyle: { "min-width": "100px", "max-width": "100px" },
            });
            this.skill_other_fields.push(skill.id);
          }
        }

        var array_age = [];
        for (var i in this.players) {
          var player = this.players[i];
          var item = {};
          item.player = player;
          for (var i in this.skills) {
            var skill = this.skills[i];
            var index = this.stats_cluster[player.id].findIndex(
              (obj) => obj.skill_id === skill.id
            );
            if (index > -1) {
              item[skill.id] = this.stats_cluster[player.id][index].skill_value;
              if (!this.skill_max[skill.id]) {
                this.skill_max[skill.id] = item[skill.id];
                this.skill_min[skill.id] = item[skill.id];
                this.skill_range[skill.id] = [];
              }
              if (item[skill.id] > this.skill_max[skill.id]) {
                this.skill_max[skill.id] = item[skill.id];
              }
              if (item[skill.id] < this.skill_min[skill.id]) {
                this.skill_min[skill.id] = item[skill.id];
              }
              this.skill_range[skill.id].push(item[skill.id]);
            } else {
              item[skill.id] = "-";
            }
          }
          item.index = 0;
          item.age = player.age;
          array_age.push(player.age);
          this.items.push(item);
        }

        this.totalRows = this.items.length;

        array_age.sort(function (a, b) {
          return a - b;
        });

        for (var r in this.skill_range) {
          this.skill_range[r].sort(function (a, b) {
            return b - a;
          });
        }

        for (var i in this.items) {
          var item = this.items[i];
          for (var s in this.skills) {
            var skill = this.skills[s];
            var value = item[skill.id];
            if (value != "-") {
              var value_norm = 0;
              if (skill.reverse) {
                value_norm = 1 - this.normalizza(skill.id, item[skill.id]);
              } else {
                value_norm = this.normalizza(skill.id, item[skill.id]);
              }
              value_norm = Math.round(value_norm * 100) / 100;
              item["norm_" + skill.id] = value_norm;
              var color = this.getBgStat(
                skill.id,
                item[skill.id],
                this.skill_range[skill.id]
              );
              item["bg_" + skill.id] = color;
            } else {
              item["norm_" + skill.id] = "-";
              item["bg_" + skill.id] = "#FFFFFF";
            }
          }
          var color_age = this.getBgStatAge(this.items[i]["age"], array_age);
          item["age_bg"] = color_age;
        }

        if (this.load_config) {
          var config = this.config_list.find(
            (item) => item.id == this.config_id
          );
          this.players_ids = JSON.parse("[" + config.players_ids + "]");
          this.skills_ids = JSON.parse("[" + config.skills_ids + "]");
          this.correlations = JSON.parse(config.correlations);

          this.calculateCorrelationAndIndex();

          this.load_config = false;
        }
        this.waiting_cluster = false;
      });
    },

    normalizza(skill_id, value) {
      var max_skill = this.skill_max[skill_id];
      var min_skill = this.skill_min[skill_id];
      if (max_skill > min_skill) {
        return (value - min_skill) / (max_skill - min_skill);
      }
      return 0;
    },

    getSkillById(id) {
      return this.skills.find((skill) => skill.id == id);
    },

    getCorrelation(skill_id) {
      var mediaX = this.getMedia(skill_id);
      var mediaY = this.getMediaPlayers();
      var num = 0;
      var denX = 0;
      var denY = 0;
      for (var i in this.items) {
        var value = this.items[i][skill_id];
        var player_id = this.items[i]["player"]["id"];
        var y = this.players_ids.includes(player_id) ? 1 : 0;
        if (value != "-") {
          num += (value - mediaX) * (y - mediaY);
          denX += Math.pow(value - mediaX, 2);
          denY += Math.pow(y - mediaY, 2);
        }
      }
      var den = Math.sqrt(denX * denY);
      var correlation = num / den;
      correlation = Math.abs(Math.round(correlation * 100) / 100);
      return correlation;
    },

    getMedia(skill_id) {
      var count = 0;
      var media = 0;
      for (var i in this.items) {
        var value = this.items[i][skill_id];
        if (value != "-") {
          media += value;
          count++;
        }
      }
      if (count > 0) {
        media = media / count;
      }
      return media;
    },

    getMediaPlayers() {
      if (this.players.length > 0) {
        return this.players_ids.length / this.players.length;
      }
      return 0;
    },

    calculateIndex() {
      this.load_config = true;
      this.calculateCorrelationAndIndex();
      this.load_config = false;
    },

    calculateCorrelationAndIndex() {
      this.waiting_index = true;

      if (!this.load_config) {
        this.correlations = {};
        var max = 0;
        var min = 0;
        for (var i in this.skills) {
          var skill = this.skills[i];
          var correlation = this.getCorrelation(skill.id);
          this.correlations[skill.id] = correlation;
          if (correlation > max) {
            max = correlation;
          }
          if (correlation < min) {
            min = correlation;
          }
        }
        for (var i in this.correlations) {
          if (!isNaN(this.correlations[i]) && max > min) {
            this.correlations[i] =
              Math.round(((this.correlations[i] - min) / (max - min)) * 100) /
              100;
          }
        }
      }
      var array_index = [];
      for (var i in this.items) {
        var player_id = this.items[i]["player"]["id"];
        var index = 0;
        for (var s in this.skills) {
          var skill = this.skills[s];
          if (
            this.skills_ids.includes(skill.id) &&
            !isNaN(this.correlations[skill.id]) &&
            !isNaN(this.items[i]["norm_" + skill.id])
          ) {
            index +=
              this.items[i]["norm_" + skill.id] * this.correlations[skill.id];
          }
        }
        index = Math.round(index * 100) / 100;
        this.items[i]["index"] = index;
        array_index.push(index);
      }

      array_index.sort(function (a, b) {
        return b - a;
      });
      for (var i in this.items) {
        var color_index = this.getBgStatIndex(
          this.items[i]["index"],
          array_index
        );
        this.items[i]["index_bg"] = color_index;
        this.items[i]["index"] =
          Math.round(
            this.normalizzaIndex(
              this.items[i]["index"],
              array_index[0],
              array_index[array_index.length - 1]
            ) * 100
          ) / 100;
      }
      this.waiting_index = false;
    },

    normalizzaIndex(index, index_max, index_min) {
      var min = 0;
      var max = 100;
      if (index_max > index_min) {
        return (
          min + ((index - index_min) / (index_max - index_min)) * (max - min)
        );
      }
      return 0;
    },

    getBgStatIndex(value, array_range) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        for (var i = 0; i <= count_array - 1; i += interval) {
          var to = i + interval;
          if (i + interval > count_array - 1) {
            to = count_array - 1;
          }
          if (index == 0 && value > parseFloat(array_range[i])) {
            return this.bg_opta[index];
          }
          if (index == 9 && value < parseFloat(array_range[to])) {
            return this.bg_opta[index];
          }
          if (
            value <= parseFloat(array_range[i]) &&
            value >= parseFloat(array_range[to])
          ) {
            return this.bg_opta[index > 9 ? 9 : index];
          }
          index++;
        }

        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },

    getBgStatAge(value, array_range) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        for (var i = 0; i <= count_array - 1; i += interval) {
          var to = i + interval;
          if (i + interval > count_array - 1) {
            to = count_array - 1;
          }
          if (index == 0 && value < parseFloat(array_range[i])) {
            return this.bg_opta[index];
          }
          if (index == 9 && value > parseFloat(array_range[to])) {
            return this.bg_opta[index];
          }
          if (
            value >= parseFloat(array_range[i]) &&
            value <= parseFloat(array_range[to])
          ) {
            return this.bg_opta[index > 9 ? 9 : index];
          }
          index++;
        }

        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },

    getBgStat(skill_id, value, array_range) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var skill = this.getSkillById(skill_id);
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        if (skill.reverse) {
          for (var i = count_array - 1; i >= 0; i -= interval) {
            var to = i - interval;
            if (i - interval < 0) {
              to = 0;
            }
            if (index == 0 && value < parseFloat(array_range[i])) {
              return this.bg_opta[index];
            }
            if (index == 9 && value > parseFloat(array_range[to])) {
              return this.bg_opta[index];
            }
            if (
              value >= parseFloat(array_range[i]) &&
              value <= parseFloat(array_range[to])
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
            index++;
          }
        } else {
          for (var i = 0; i <= count_array - 1; i += interval) {
            var to = i + interval;
            if (i + interval > count_array - 1) {
              to = count_array - 1;
            }
            if (index == 0 && value > parseFloat(array_range[i])) {
              return this.bg_opta[index];
            }
            if (index == 9 && value < parseFloat(array_range[to])) {
              return this.bg_opta[index];
            }
            if (
              value <= parseFloat(array_range[i]) &&
              value >= parseFloat(array_range[to])
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
            index++;
          }
        }
        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },

    selectPrinciples(index) {
      this.select_skills[index] = !this.select_skills[index];
      if (!this.select_skills[index]) {
        this.skills_ids = this.skills_ids.filter(
          (item) => !this.principles_skills[index].includes(item)
        );
      } else {
        for (var i in this.principles_skills[index]) {
          var skill_id = this.principles_skills[index][i];
          if (!this.skills_ids.includes(skill_id)) {
            this.skills_ids.push(skill_id);
          }
        }
      }
    },

    selectOthers(index) {
      this.select_skills[index] = !this.select_skills[index];
      if (!this.select_skills[index]) {
        this.skills_ids = this.skills_ids.filter(
          (item) => !this.skill_other_fields.includes(item)
        );
      } else {
        for (var i in this.skill_other_fields) {
          var skill_id = this.skill_other_fields[i];
          if (
            !this.skills_ids.includes(skill_id) &&
            !this.no_weight_skills_id.includes(skill_id)
          ) {
            this.skills_ids.push(skill_id);
          }
        }
      }
    },

    selectAllSkills() {
      if (this.select_all_skills) {
        this.select_skills = [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ];
        this.skills_ids = [];
        this.select_all_skills = false;
      } else {
        this.select_skills = [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ];
        for (var i in this.skills) {
          if (
            !this.skills_ids.includes(this.skills[i].id) &&
            !this.no_weight_skills_id.includes(this.skills[i].id)
          ) {
            this.skills_ids.push(this.skills[i].id);
          }
        }
        this.select_all_skills = true;
      }
    },

    selectMicro() {
      if (this.select_micro) {
        this.select_skills = [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ];
        for (var i in this.skills) {
          if (
            !this.skills_ids.includes(this.skills[i].id) &&
            !this.no_weight_skills_id.includes(this.skills[i].id)
          ) {
            this.skills_ids.push(this.skills[i].id);
          }
        }
        this.select_all_skills = true;
        this.select_micro = false;
      } else {
        this.skills_ids = [];
        for (var i in this.skills) {
          var skill = this.skills[i];
          if (
            skill.micro.findIndex(
              (item) => item.gruppo == this.form.group && item.micro == 1
            ) > -1
          ) {
            this.skills_ids.push(skill.id);
          }
        }
        this.select_micro = true;
      }
    },

    resetPlayers() {
      this.players_ids = [];
    },

    applyCorrelationWeights() {
      if (
        confirm("Are you sure you want to apply this correlation weights ?")
      ) {
        this.$http
          .post(
            "/opta/apply/correlation/weights/" + this.form.group,
            this.correlations
          )
          .then((response) => {
            this.$store.commit("msgPush", {
              msg: "Weights apply succesfully!",
              status: 1,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    isMyConfig(id) {
      var config = this.config_list.find((item) => item.id == id);
      if (config.utente_id == this.auth.user.id) {
        return true;
      }
      return false;
    },
  },

  computed: {
    auth: function () {
      return Auth;
    },

    excel_data: function () {
      var data = [];
      for (var i in this.items) {
        var item = this.items[i];
        var row = {
          giocatore: item.player.name,
          ruolo: item.player.role,
          index: item.index,
          age: item.age,
        };
        for (var s in this.skill_princ_fields) {
          var skill_id = this.skill_princ_fields[s];
          var skill = this.getSkillById(skill_id);
          row[skill.name] = this.norm_view
            ? item["norm_" + skill_id]
            : item[skill_id];
        }
        for (var s in this.skill_other_fields) {
          var skill_id = this.skill_other_fields[s];
          var skill = this.getSkillById(skill_id);
          row[skill.name] = this.norm_view
            ? item["norm_" + skill_id]
            : item[skill_id];
        }
        row["bravo"] = this.players_ids.includes(item.player.id) ? 1 : 0;
        data.push(row);
      }
      var row_ins = {
        giocatore: "INSERIRE",
        ruolo: "",
        index: "",
        age: "",
      };
      var row_corr = {
        giocatore: "CORRELAZIONE",
        ruolo: "",
        index: "",
        age: "",
      };
      for (var s in this.skill_princ_fields) {
        var skill_id = this.skill_princ_fields[s];
        var skill = this.getSkillById(skill_id);

        row_ins[skill.name] = this.skills_ids.includes(skill_id) ? 1 : 0;
        row_corr[skill.name] = this.correlations[skill_id];
      }
      for (var s in this.skill_other_fields) {
        var skill_id = this.skill_other_fields[s];
        var skill = this.getSkillById(skill_id);

        row_ins[skill.name] = this.skills_ids.includes(skill_id) ? 1 : 0;
        row_corr[skill.name] = this.correlations[skill_id];
      }
      row_ins["bravo"] = "";
      row_corr["bravo"] = "";
      data.push(row_ins);
      data.push(row_corr);
      return data;
    },

    items_weight: function () {
      var items = [];
      for (var id in this.correlations) {
        var skill = this.getSkillById(id);
        var item = {
          skill: skill,
          weight: this.correlations[id],
        };
        items.push(item);
      }
      return items;
    },

    pageOptions() {
      return [15, 30, 50, 100, { value: this.totalRows, text: "All" }];
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
